import ModalForm from '../ModalForm';
import { useEffect, useState } from 'react';
import Button from '@/components/button/Button';
import ModalPinCodeCheckSMSCode from '@/components/modal/pin/ModalPinCodeCheckSMSCode';
import useModals from '@/src/hooks/useModals';
import { format } from '@/src/modules/Common';
import useNotifications from '@/src/hooks/useNotifications';
import AuthBtnSet from '@/components/auth-btnset/AuthBtnSet';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { LAYERS, modalViewEvent } from '@/src/modules/events/GTM';

export default function ModalPinCodeSelectPhone({ pinCode, contacts, callback, action }) {
    const { api } = useApiStore();
    const { openModal } = useModals();
    const { showError } = useNotifications();

    const [submittingContactId, setSubmittingContactId] = useState(-1);

    useEffect(() => {
        modalViewEvent('ModalPinCodeSelectPhone', LAYERS.MODAL_PIN_CODE);
    }, []);

    const contactsSelect = contacts.map((contact, index) => ({
        ...contact,
        text: format(contact.value.slice(1), '+7 (###) ###-##-##'),
        index: index,
    }));

    const onSelectContact = async (contact) => {
        if (submittingContactId !== -1) {
            return;
        }
        setSubmittingContactId(contact.id);
        try {
            await api.generalInformation.requestSMSCodeByContactId(contact.id);
            openModal(
                <ModalPinCodeCheckSMSCode pinCode={pinCode} contact={contact} callback={callback} action={action} />
            );
        } catch (e) {
            setSubmittingContactId(-1);
            if (e.code === 'SMS already send') {
                openModal(
                    <ModalPinCodeCheckSMSCode pinCode={pinCode} contact={contact} callback={callback} action={action} />
                );
            } else {
                showError(e.message || 'Ошибка при отправке СМС');
            }
        }
    };

    return (
        <ModalForm
            closeButton={true}
            pageUrl={'#pin-code-select-phone'}
            pageTitle={'Выберите номер телефона'}
            className={'modal--primary modal--parental-preview'}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <img className="modal__img" src="/assets/img/modal-parental-control.jpg" alt="" />
            </div>
            <div className="modal__form">
                <div className="modal__content-title modal__content-title--center">Выберите номер телефона</div>
                <div className="modal__content-subtitle">
                    Мы&nbsp;нашли несколько номеров, привязанных к&nbsp;вашему договору. Выберите номер телефона,
                    на&nbsp;который отправить СМС с&nbsp;кодом.
                </div>
                <div className="modal__fieldset">
                    <div className="modal__field">
                        <AuthBtnSet modifier={'modal__btnset'}>
                            <div className="auth-btnset__inner">
                                {contactsSelect.map((item) => (
                                    <div className="auth__arrow-btn" key={item['id']}>
                                        <Button
                                            type={'submit'}
                                            className={'button--wide button--clear auth__btn--arrow'}
                                            title={item['text']}
                                            icon={'arrow-right'}
                                            onClick={() => onSelectContact(item)}
                                            submitting={item.id === submittingContactId}
                                        />
                                    </div>
                                ))}
                            </div>
                        </AuthBtnSet>
                    </div>
                </div>
            </div>
        </ModalForm>
    );
}
