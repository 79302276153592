import { useEffect, useRef, useState } from 'react';
import { format } from '@/src/modules/Common';
import ModalForm from '@/components/modal/ModalForm';
import Button from '@/components/button/Button';
import InputPinCode from '@/components/input/InputPinCode';
import useModals from '@/src/hooks/useModals';
import { ModalPinCodeAction } from '@/components/modal/pin/ModalPinCode';
import { useApi } from '@/src/hooks/useApi';
import Loader from '@/components/modal/helpers/Loader';
import { getTimeString } from '@/src/helpers/FormatHelper';
import { replaceAll } from '@/src/helpers/PolyfillHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { LAYERS, modalViewEvent } from '@/src/modules/events/GTM';

const SECONDS_TO_REQUEST_NEW_CODE = 60;

/**
 * @param {string} pinCode
 * @param {Object} contact
 * @param {function} callback
 * @param {string} action
 */
export default function ModalPinCodeCheckSMSCode({ pinCode, contact, callback, action }) {
    const { api } = useApiStore();
    const supportPhone = /** @type {string} */ useApi(api.generalInformation.getSupportPhone);
    const { closeModal } = useModals();

    const [submitting, setSubmitting] = useState(false);
    const [repeatSubmitting, setRepeatSubmitting] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const codeRef = useRef(null);
    const [secondsToRequestNewCode, setSecondsToRequestNewCode] = useState(SECONDS_TO_REQUEST_NEW_CODE);

    useEffect(() => {
        modalViewEvent('ModalPinCodeCheckSMSCode', LAYERS.MODAL_PIN_CODE);
        if (codeRef.current) {
            codeRef.current.focus();
        }
        const timer = setInterval(() => {
            setSecondsToRequestNewCode((secondsToRequestNewCode) => secondsToRequestNewCode - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const phone = contact.value;

    async function onConfirm(code) {
        setSubmitting(true);
        try {
            let status;
            switch (action) {
                case ModalPinCodeAction.PIN_ENABLE:
                case ModalPinCodeAction.PIN_ENABLE_FROM_ADULT_SOURCE:
                    status = await api.parentalControl.pinEnable(contact.id, pinCode, code);
                    break;
                case ModalPinCodeAction.PIN_DISABLE:
                    status = await api.parentalControl.pinDisable(contact.id, pinCode, code);
                    break;
                case ModalPinCodeAction.SET_INAPPROPRIATE_CONTENT_ENABLED:
                    status = await api.parentalControl.pinChange({
                        inappropriateContent: true,
                        contactId: contact.id,
                        code: code,
                    });
                    break;
                case ModalPinCodeAction.SET_INAPPROPRIATE_CONTENT_DISABLED:
                    status = await api.parentalControl.pinChange({
                        inappropriateContent: false,
                        contactId: contact.id,
                        code: code,
                    });
                    break;
                case ModalPinCodeAction.PIN_RESET:
                    status = await api.parentalControl.pinChange({ pin: pinCode, contactId: contact.id, code: code });
                    break;
                default:
                // nothing
            }
            closeModal();
            if (callback && typeof callback === 'function') {
                callback(status);
            }
        } catch (e) {
            setError(e.message || 'Ошибка подтверждения телефона');
            setSubmitting(false);
            setTimeout(async () => {
                codeRef.current.focus();
            }, 0);
        }
    }

    function onChange(newValue) {
        setValue(newValue);
        setError('');
        if (newValue.length === 4) {
            // noinspection JSIgnoredPromiseFromCall
            onConfirm(newValue);
        }
    }

    async function onRepeatSendSms(event) {
        event.preventDefault();
        setRepeatSubmitting(true);
        try {
            await api.generalInformation.requestSMSCodeByContactId(contact.id);
            setSecondsToRequestNewCode(SECONDS_TO_REQUEST_NEW_CODE);
        } catch (e) {
            setError(e.message || 'Ошибка отправки кода для подтверждения телефона');
        } finally {
            setRepeatSubmitting(false);
        }
    }

    return (
        <ModalForm
            closeButton={true}
            pageUrl={'#pin-code'}
            pageTitle={'Код из СМС'}
            className={'modal--primary modal--parental-preview'}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <img className="modal__img" src="/assets/img/modal-parental-control.jpg" alt="" />
            </div>
            <form className="modal__form">
                <div className="modal__content-title modal__content-title--center">Введите код из СМС</div>
                <div className="modal__content-subtitle">
                    Отправили его на номер <nobr>{format(phone.slice(1), '+7 (###) ###-##-##')}</nobr> — так мы
                    убедимся, что это точно вы. Если у вас нет доступа к телефону, можете позвонить нам по номеру{' '}
                    <nobr>{replaceAll(supportPhone, '-', ' ')}</nobr>
                </div>
                <div className="modal__fieldset">
                    <div className="modal__field">
                        {submitting ? (
                            <Loader />
                        ) : (
                            <InputPinCode
                                inputRef={codeRef}
                                className={'input--code-large'}
                                value={value}
                                error={error}
                                onChange={onChange}
                            />
                        )}
                    </div>
                    <div className="modal__field">
                        {secondsToRequestNewCode > 0 && (
                            <div className="auth__timer auth__secondary-text">
                                <div className="modal__field">
                                    Получить новый код можно через {getTimeString(secondsToRequestNewCode)}
                                </div>
                            </div>
                        )}
                        {secondsToRequestNewCode <= 0 && (
                            <div className="modal__wrapper-btn">
                                <Button
                                    className={'button--clear'}
                                    title="Получить новый код"
                                    onClick={onRepeatSendSms}
                                    submitting={repeatSubmitting}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </ModalForm>
    );
}
