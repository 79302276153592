import ModalForm from '../ModalForm';
import { useApi } from '@/src/hooks/useApi';
import { replaceAll } from '@/src/helpers/PolyfillHelper';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { LAYERS, modalViewEvent } from '@/src/modules/events/GTM';
import { useEffect } from 'react';

export default function ModalPinCodeContactPhoneNotFound() {
    const { api } = useApiStore();
    const supportPhone = /** @type {string} */ useApi(api.generalInformation.getSupportPhone);

    useEffect(() => {
        modalViewEvent('ModalPinCodeContactPhoneNotFound', LAYERS.MODAL_PIN_CODE);
    }, []);

    return (
        <ModalForm
            closeButton={true}
            pageUrl={'#pin-code-contact-phone-not-found'}
            pageTitle={'Не смогли найти ваш номер телефона'}
            className={'modal--primary modal--parental-preview'}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <img className="modal__img" src="/assets/img/modal-parental-control.jpg" alt="" />
            </div>
            <div className="modal__form">
                <div className="modal__content-title modal__content-title--center">
                    Не&nbsp;смогли найти ваш номер телефона
                </div>
                <div className="modal__content-subtitle">
                    Пожалуйста, позвоните в&nbsp;службу поддержки по&nbsp;номеру{' '}
                    <nobr>{replaceAll(supportPhone, '-', ' ')}</nobr>, чтобы привязать к&nbsp;вашему аккаунту актуальный
                    номер телефона. Или сделайте это самостоятельно в профиле.
                </div>
            </div>
        </ModalForm>
    );
}
