import styles from '../Modal.module.scss';
import { useEffect, useRef, useState } from 'react';
import ModalForm from '../ModalForm';
import Common from '@/src/modules/Common';
import InputPinCode from '@/components/input/InputPinCode';
import Loader from '@/components/modal/helpers/Loader';
import useModals from '@/src/hooks/useModals';
import Contact from '@/src/api/v2/models/Contact';
import ModalPinCodeSelectPhone from '@/components/modal/pin/ModalPinCodeSelectPhone';
import ModalPinCodeContactPhoneNotFound from '@/components/modal/pin/ModalPinCodeContactPhoneNotFound';
import ModalPinCodeCheckSMSCode from '@/components/modal/pin/ModalPinCodeCheckSMSCode';
import useNotifications from '@/src/hooks/useNotifications';
import { ButtonPrimary } from '@/components/button/Button';
import { useApiStore } from '@/src/stores/RootStoreProvider';
import { clickButtonEvent, LAYERS, modalViewEvent } from '@/src/modules/events/GTM';

export default function ModalPinCode({ callback, closeModalWhenSuccess = true, showDisablePinButton = false }) {
    const { api } = useApiStore();
    const { closeModal } = useModals();

    const [submitting, setSubmitting] = useState(false);
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    const pinCode = useRef(null);

    useEffect(() => {
        if (pinCode.current && !submitting) {
            pinCode.current.focus();
        }
    }, [submitting]);

    useEffect(() => {
        modalViewEvent('ModalPinCode', LAYERS.MODAL_PIN_CODE);
    }, []);

    const validate = (pin) => {
        return pin.length === 4;
    };

    const onPinInputChanged = (newValue) => {
        setValue(newValue);
        setError(null);
        if (newValue.length === 4) {
            // noinspection JSIgnoredPromiseFromCall
            onSubmit(newValue);
        }
    };

    const onKeyDown = (event) => {
        if (Common.isPressEnter(event)) {
            // noinspection JSIgnoredPromiseFromCall
            onSubmit(event.target.value);
            event.stopPropagation();
            event.preventDefault();
        }
    };

    const onSubmit = async (pin) => {
        if (submitting) {
            return;
        }
        if (!validate(pin)) {
            return;
        }
        setSubmitting(true);

        try {
            await api.parentalControl.pinCheck(pin);
            if (closeModalWhenSuccess || !callback) {
                closeModal();
            }
            if (callback && typeof callback === 'function') {
                await callback(pin);
            }
        } catch (e) {
            setError(e.message || 'Ошибка при проверке пин-кода');
            setSubmitting(false);
        }
    };

    const onDisablePin = () => {
        clickButtonEvent('disablePin', LAYERS.MODAL_PIN_CODE);
    };

    return (
        <ModalForm
            pageUrl={'#pin-code'}
            pageTitle={'Ввод пин-кода'}
            className={'modal--primary modal--parental-preview'}
        >
            <div className="modal__img-wrapper modal__img-wrapper--shadow">
                <img className="modal__img" src="/assets/img/modal-parental-control.jpg" alt="" />
            </div>
            <form className="modal__form" onSubmit={onSubmit}>
                <div className="modal__content-title modal__content-title--center">Введите пин-код</div>
                <div className={'modal__fieldset'}>
                    <div className={styles['modal__field']}>
                        {submitting ? (
                            <Loader />
                        ) : (
                            <InputPinCode
                                className={'input--code-large'}
                                inputRef={pinCode}
                                value={value}
                                error={error}
                                onChange={onPinInputChanged}
                                onFocus={() => setError('')}
                                onKeyDown={onKeyDown}
                                type={'password'}
                            />
                        )}
                    </div>
                </div>
                {showDisablePinButton && (
                    <div className="modal__btnset">
                        <div className="modal__wrapper-btn">
                            <ButtonPrimary
                                onClick={onDisablePin}
                                type={'link'}
                                className={'button--secondary'}
                                title={'Отключить пин-код'}
                                href={'/profile/pin-code'}
                                submitting={submitting}
                            />
                        </div>
                    </div>
                )}
            </form>
        </ModalForm>
    );
}

export const ModalPinCodeAction = {
    PIN_ENABLE: 'PinEnable',
    PIN_ENABLE_FROM_ADULT_SOURCE: 'PinEnableFromAdultSource',
    PIN_RESET: 'PinReset',
    PIN_DISABLE: 'PinDisable',
    SET_INAPPROPRIATE_CONTENT_ENABLED: 'SetInappropriateContentEnabled',
    SET_INAPPROPRIATE_CONTENT_DISABLED: 'SetInappropriateContentDisabled',
};

export function useCheckSMS() {
    const { api } = useApiStore();
    const { openModal } = useModals();
    const { showError } = useNotifications();

    const checkSMS = async (action, callback, pinCode) => {
        const contacts = await api.generalInformation.getContacts();
        const phones = contacts.filter((contact) => contact.type === Contact.TYPE_PHONE);
        if (phones.length > 1) {
            openModal(
                <ModalPinCodeSelectPhone contacts={phones} callback={callback} action={action} pinCode={pinCode} />
            );
        } else if (phones.length === 1) {
            const contact = phones[0];
            try {
                await api.generalInformation.requestSMSCodeByContactId(contact.id);
                openModal(
                    <ModalPinCodeCheckSMSCode pinCode={pinCode} contact={contact} callback={callback} action={action} />
                );
            } catch (e) {
                if (e.code === 'SMS already send') {
                    openModal(
                        <ModalPinCodeCheckSMSCode
                            pinCode={pinCode}
                            contact={contact}
                            callback={callback}
                            action={action}
                        />
                    );
                } else {
                    showError(e.message || 'Ошибка при отправке СМС');
                }
            }
        } else {
            openModal(<ModalPinCodeContactPhoneNotFound />);
        }
    };

    return { checkSMS };
}
