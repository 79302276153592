import React from 'react';

const keyLeft = 37;
const keyRight = 39;
const keyDelete = 46;
const keyBackspace = 8;
const keyEnter = 13;
const keyHome = 36;
const keyEnd = 35;
const placeholder = '•';

class InputPinCode extends React.PureComponent {
    state = {
        digit1: '',
        digit2: '',
        digit3: '',
        digit4: '',
    };

    constructor(props) {
        super(props);

        this.digit1 = this.props.inputRef || React.createRef();
        this.digit4 = React.createRef();
    }

    onFocus() {
        if (this.digit1.current && !this.digit1.current.value) {
            this.digit1.current.focus();
        }
    }

    setValue(value, target) {
        if (value === placeholder) {
            return;
        }
        value = value.replace(/\D/g, '');
        switch (target.id) {
            case 'digit1':
                this.props.onChange?.(value + this.state.digit2 + this.state.digit3 + this.state.digit4);
                this.setState({ digit1: value });
                break;
            case 'digit2':
                this.props.onChange?.(this.state.digit1 + value + this.state.digit3 + this.state.digit4);
                this.setState({ digit2: value });
                break;
            case 'digit3':
                this.props.onChange?.(this.state.digit1 + this.state.digit2 + value + this.state.digit4);
                this.setState({ digit3: value });
                break;
            case 'digit4':
                this.props.onChange?.(this.state.digit1 + this.state.digit2 + this.state.digit3 + value);
                this.setState({ digit4: value });
                break;
            default:
                break;
        }
        if (value) {
            if (target.nextSibling && typeof target.nextSibling.focus === 'function') {
                target.nextSibling.focus();
            }
        }
    }

    onChange(event) {
        let value = event.target.value;
        this.setValue(value, event.target);
    }

    onKeyDown(event) {
        switch (event.keyCode) {
            case keyBackspace:
            case keyLeft:
            case keyDelete:
            case keyRight:
            case keyEnter:
                break;
            case keyHome:
                this.digit1.current.focus();
                this.digit1.current.select();
                break;
            case keyEnd:
                this.digit4.current.focus();
                this.digit4.current.select();
                break;
            default:
                if (event.key && event.target.value) {
                    this.setValue(event.key, event.target);
                }
        }
    }

    onKeyUp(event) {
        switch (event.keyCode) {
            case keyBackspace:
            case keyLeft:
                if (event.target.previousSibling) {
                    event.target.previousSibling.focus();
                    event.target.previousSibling.select();
                }
                break;
            case keyDelete:
            case keyRight:
                if (event.target.nextSibling && typeof event.target.nextSibling.focus === 'function') {
                    event.target.nextSibling.focus();
                    event.target.nextSibling.select();
                }
                break;
            case keyHome:
            case keyEnd:
            default:
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.value && prevProps.value) {
            this.setState({
                digit1: '',
                digit2: '',
                digit3: '',
                digit4: '',
            });
        }
    }

    render() {
        return (
            <div
                className={
                    'input input--code' +
                    (this.props.className ? ' ' + this.props.className : '') +
                    (this.props.error ? ' input--code-error' : '')
                }
            >
                <div className={'input__pad'} onFocus={this.onFocus.bind(this)}>
                    {[1, 2, 3, 4].map((i) => {
                        const digit = `digit${i}`;
                        let value = this.state[digit];
                        return (
                            <input
                                key={i}
                                id={digit}
                                className={`input__code${value ? ' input__code--full' : ''}`}
                                placeholder={placeholder}
                                maxLength={1}
                                autoComplete="off"
                                required={true}
                                value={value.length ? placeholder : ''}
                                onChange={this.onChange.bind(this)}
                                onKeyDown={this.onKeyDown.bind(this)}
                                onKeyUp={this.onKeyUp.bind(this)}
                                type="text"
                                ref={this[digit]}
                            />
                        );
                    })}
                    <div className="input__bg"> </div>
                </div>
                {this.props.error?.length ? (
                    <p className="input__helptext input__helptext--code input__helptext--warning">{this.props.error}</p>
                ) : (
                    ''
                )}
                <label className="input__label input__label--hidden" htmlFor="digit1">
                    {this.props.title}
                </label>
            </div>
        );
    }
}

export default InputPinCode;
